<template>
  <div class="w-full flex flex-col flex-grow p-4 gap-4">
    <p class="text-sm">
      {{ employeeDetails.fname }}
      {{ employeeDetails.lname }} is currently profiled for
      {{ rolesData.length }}
      administrative role(s). Before proceeding, we recommend that you delete
      him/her from these role(s). You may have to assign this role to another
      employee for continuity.
    </p>
    <div class="w-full flex justify-center">
      <div class="my-3 w-full border" />
    </div>

    <div style="height: 100%" class="mt-4 pt-5" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <div class="flex flex-col w-full my-2" v-if="rolesData.length > 0">
        <sTable
          :headers="rolesHeaders"
          :items="rolesData"
          style="width: 100%"
          aria-label="employee table"
          class="w-full"
          has-number
        >
          <template v-slot:item="{ item }">
            <div v-if="item.roleId" class="py-2">
              {{ item.data.roleId ? item.data.roleId : "---" }}
            </div>
            <div
              v-else-if="item.roleName"
              class="py-2 w-full flex flex-col gap-1"
            >
              <p class="text-sm">
                {{ item.data.roleName ? item.data.roleName : "---" }}
              </p>
              <span class="text-sm text-romanSilver flex gap-1">
                <p>
                  {{
                    item.data.users
                      ? `${item.data.users} other people are assigned to this role`
                      : ""
                  }}
                </p>
                <p
                  class="text-blueCrayola underline text-xs uppercase flex-col flex justify-center cursor-pointer"
                  @click="viewRoles(item.data)"
                >
                  View
                </p>
              </span>
            </div>
            <div v-else-if="item.id" class="flex">
              <div class="flex gap-8">
                <span class="cursor-pointer" @click="setUpModal(item.data.id)">
                  <Icon
                    icon-name="icon-trash"
                    size="xs"
                    class-name="text-desire"
                  />
                </span>
              </div>
            </div>
          </template>
        </sTable>
      </div>
    </div>

    <Alert
      message="As soon as you click next, this action becomes permanent, even if you cancel the termination process. You will have to re-assign this privilege to this employee if you decide to cancel and want the employee to retain assigned privileges."
      variant="primary"
      :time="2"
      style="
        position: relative;
        right: 0;
        top: -5px;
        box-shadow: none;
        background: #fff5f5;
        color: #ea3c53;
        padding: 12px 16px;
        border: 1px solid #ea3c53;
      "
    />

    <Modal v-if="roleModal">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete Employee from this role?
          </p>
          <div class="flex w-full">
            <Button
              class="text-white bg-flame rounded-md cursor-pointer p-3 w-full"
              @click="deleteRole(roleId)"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid p-3 w-full rounded-md cursor-pointer border-dynamicBackBtn ml-4"
              @click="roleModal = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>

    <RightSideBar
      v-if="openModal"
      @close="openModal = false"
      :loading="isLoading"
      close-button
    >
      <template v-slot:title>
        <div class="flex flex-col gap-1 my-1 py-1 w-full">
          <p class="text-lg font-bold text-darkPurple">View {{ roleTitle }}</p>
        </div>
      </template>

      <div class="flex flex-col gap-10 w-full my-3">
        <div
          class="flex gap-3 w-full py-3 justify-start border-b border-dashed"
          v-for="(roleUser, index) in roleUsers"
          :key="index"
        >
          <div>
            <img
              v-if="roleUser.photo"
              :src="roleUser.photo"
              alt="profile pic"
              class="w-10 h-10 rounded"
            />
            <div
              v-else
              class="w-10 h-10 rounded text-blueCrayola border text-center font-semibold pt-2"
            >
              {{ $getInitials(`${roleUser.fname} ${roleUser.lname}`) }}
            </div>
          </div>
          <div class="flex flex-col gap-1 justify-center">
            <p class="text-base font-semibold">
              {{ roleUser.fname }} {{ roleUser.lname }}
            </p>
            <p class="text-xs font-bold text-romanSilver uppercase">
            {{ roleUser.userDesignation.name }}</p>
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Modal from "@/components/Modal";
import Card from "@/components/Card";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "Administrative",
  components: { STable, Card, Modal, Alert, RightSideBar },
  data() {
    return {
      loading: true,
      isLoading: true,
      rolesHeaders: [
        { title: "Role Id", value: "roleId", width: 25 },
        { title: "Role Name", value: "roleName", width: 65 },
        { title: "", value: "id", image: true, width: 10 },
      ],
      rolesData: [],
      roleModal: false,
      openModal: false,
      roleTitle: "",
      roleId: "",
      roleUsers: [],
    };
  },
  props: {
    employeeDetails: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    setUpModal(id) {
      this.roleId = id;
      this.roleModal = true;
    },
    fetchEmployeeRoles() {
      this.rolesData = [];
      this.$_getEmployeeInsightRoles(this.employeeDetails.userId)
        .then((response) => {
          this.loading = false;
          const data = response.data.data.roles;
          this.rolesData = data.map((roleData) => ({
            ...roleData,
            id: roleData.role.id,
            roleName: `${roleData.role.name}`,
            users: roleData.others > 1 ? roleData.others : null,
          }));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewRoles(role) {
      this.openModal = true;
      this.roleTitle = role.roleName;
      this.$_getUsersInsightRoles(role.id).then((response) => {
        this.roleUsers = response.data.data.users;
        this.isLoading = false;
      });
    },
    deleteRole(id) {
      this.$_deleteRole(this.employeeDetails.userId, id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.loading = true;
          this.roleModal = false;
          this.fetchEmployeeRoles();
        })
        .catch((error) => {
          this.$toasted.error(error, {
            duration: 5000,
          });
        });
    },
  },
  mounted() {
    this.fetchEmployeeRoles();
  },
};
</script>
