<template>
  <div class="w-full flex flex-col gap-8 ml-3">
    <Alert
      message="Congratulations! You have finished going through the disengagement checklist. Please confirm that you are ready to disengage and exit. This action will take effect as soon as it is approved."
      variant="primary"
      class="alert-style"
    />

    <div class="flex w-full justify-end gap-3">
      <Button
        class="font-semibold text-darkPurple"
        width="7rem"
        @click="saveAsDraft"
      >
        Save as Draft
      </Button>
      <Button
        class="font-semibold text-white"
        :background="appearance.buttonColor || defaultBTNColor"
        @click="confirmSubmit()"
        width="7rem"
      >
        Confirm
      </Button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Button from "@/components/Button";

export default {
  name: "Confirm",
  components: { Alert, Button },
  props: {
    payloadData: {
      type: Object,
      default: () => {},
    },
    employeeDetails: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    saveAsDraft() {
      this.payloadData.status = "draft";
      this.$_disengageOrgEmployee(this.payloadData)
        .then(() => {
          const message =
            "Employee disengage action has been sent for Approval";
            this.$toasted.success(message, { duration: 3000 });
            this.$router.push({ name: 'EmployeeDirectory' });
        })
        .catch((error) => {
          this.$toasted.error(error, { duration: 3000 });
        });
    },

      confirmSubmit() {
      this.payloadData.status = null;
      this.$_disengageOrgEmployee(this.payloadData)
        .then(() => {
          const message =
            "Employee disengage action has been sent for Approval";
            this.$toasted.success(message, { duration: 3000 });
            this.$router.push({ name: 'EmployeeDirectory' });
        })
        .catch((error) => {
          this.$toasted.error(error, { duration: 3000 });
        });
    },
  },
};
</script>

<style scoped>
.alert-style {
  position: relative;
  right: 0;
  top: -5px;
  box-shadow: none;
  background: rgba(19, 181, 106, 0.06);
  color: rgba(19, 181, 106, 1);
  font-size: 16px;
  border: 1px solid rgba(19, 181, 106, 1);
}
</style>
