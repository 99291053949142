<template>
  <div class="w-full flex flex-col flex-grow p-4 gap-4 border ml-3">
    <p class="text-romanSilver text-sm">Comment</p>
    <p class="text-sm">
      You have initiated the process of disengaging {{ employeeDetails.fname }}
      {{ employeeDetails.lname }} from your organization. Before you proceed,
      please review the disengagement checklist to ensure that your operations
      continue to run smoothly after the disengagement and to account for any
      terminal benefits, debts, and payroll needs.
    </p>
  </div>
</template>

<script>
export default {
  name: "Intro",
  props: {
    employeeDetails: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
