<template>
  <div class="w-full flex flex-col flex-grow p-4 gap-4">
    <p class="text-sm">
      {{ employeeDetails.fname }}
      {{ employeeDetails.lname }} is currently profiled for
      {{ approvalsData.length }}
      approval workflow(s). Before proceeding, we recommend that you delete
      him/her from these workflow(s). You may have to assign this approval
      privileges to another employee.
    </p>
    <div class="w-full flex justify-center">
      <div class="my-3 w-full border" />
    </div>

    <div style="height: 100%" class="mt-4 pt-5" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <div class="flex flex-col w-full my-3" v-if="approvalsData.length > 0">
        <sTable
          :headers="approvalHeader"
          :items="approvalsData"
          style="width: 100%"
          aria-label="employee table"
          class="w-full"
          has-number
        >
          <template v-slot:item="{ item }">
            <div v-if="item.flowId" class="p-2">
              {{ item.data.flowId ? item.data.flowId : "---" }}
            </div>
            <div v-else-if="item.workFlowName" class="p-2">
              {{ item.data.workFlowName ? item.data.workFlowName : "---" }}
            </div>
            <div v-else-if="item.initiatorType" class="p-2 capitalize">
              {{ item.data.initiatorType ? item.data.initiatorType : "---" }}
            </div>
            <div v-else-if="item.approversCount" class="p-2 capitalize">
              {{ item.data.approversCount ? item.data.approversCount : "---" }}
            </div>
            <div v-else-if="item.groupId">
              <div
                class="w-full flex justify-start items-center gap-2 cursor-pointer"
                @click="
                  $router.push({
                    name: 'ViewWorkflow',
                    params: { workflowId: item.data.groupId },
                  })
                "
              >
                <Icon
                  icon-name="edit"
                  class-name="text-blueCrayola"
                  size="xs"
                />
              </div>
            </div>
          </template>
        </sTable>
      </div>
    </div>

    <Alert
      message="As soon as you click next, this action becomes permanent, even if you cancel the termination process. You will have to re-assign this privilege to this employee if you decide to cancel and want the employee to retain assigned privileges."
      variant="primary"
      :time="2"
      style="
        position: relative;
        right: 0;
        top: -5px;
        box-shadow: none;
        background: #fff5f5;
        color: #ea3c53;
        padding: 12px 16px;
        border: 1px solid #ea3c53;
      "
    />

  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";

export default {
  components: { STable,Alert },
  data() {
    return {
      loading: true,
      approvalHeader: [
        { title: "Workflow Id", value: "flowId" },
        { title: "Workflow Name", value: "workFlowName" },
        { title: "Scope", value: "initiatorType" },
        { title: "Sequence", value: "approversCount" },
        { title: "", value: "groupId", image: true },
      ],
      approvalsData: [],
    };
  },
  props: {
    employeeDetails: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$_getEmployeeApprovalInsights(this.employeeDetails.userId).then(
      (response) => {
        this.approvalsData = response.data.data.empApproval;
        this.loading = false;
      }
    );
  },
};
</script>
