<template>
  <div class="w-full flex flex-col flex-grow p-4 gap-4">
    <p class="text-sm">
      {{ employeeDetails.fname }}
      {{ employeeDetails.lname }} currently manages 5 employees. Before
      proceeding, we recommend that you delegate management of these employees
      to another employee.
    </p>
    <div class="w-full flex justify-center">
      <div class="my-3 w-full border" />
    </div>

    <div class="w-full flex flex-col my-3 gap-4">
      <div class="w-full flex justify-between items-center gap-3">
        <div class="flex w-full">
          <c-select
            placeholder="--Select--"
            label="Delegatee"
            variant="w-full"
            class="w-full"
            v-model="delegatee"
            :options="delegateeArray"
          />
        </div>
        <div class="flex w-full">
          <c-text
            label="Reason for Delegation"
            placeholder="Enter"
            class="w-full"
            v-model="delegationReason"
            disabled
          />
        </div>
        <div class="flex w-full">
          <div
            class="leading-5 text-jet flex flex-col justify-start gap-2 w-full"
          >
            <p class="font-normal text-sm text-jet">Delegation Period</p>
            <div class="rounded-md w-full h-full">
              <div class="flex justify-start items-center gap-2 w-full h-10">
                <div class="h-10 mt-1 w-full">
                  <date-range-picker
                    v-model="delegationPeriod"
                    placeholder="Select Period"
                    name="period"
                    range
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-end gap-2 text-flame">
        <div class="flex gap-1 cursor-pointer" @click="onSubmit()">
          <Icon icon-name="icon-plus" size="xs" />
          <span class="font-semibold text-base text-flame leading-5">
            Add Delegatee
          </span>
        </div>
      </div>
      <div class="w-full flex justify-center">
        <div class="my-3 w-full border" />
      </div>
      <Alert
        message="As soon as you click next, this action becomes permanent, even if you cancel the termination process. You will have to re-assign this privilege to this employee if you decide to cancel and want the employee to retain assigned privileges."
        variant="primary"
        :time="2"
        style="
          position: relative;
          right: 0;
          top: -5px;
          box-shadow: none;
          background: #fff5f5;
          color: #ea3c53;
          padding: 12px 16px;
          border: 1px solid #ea3c53;
        "
      />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import DateRangePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";

export default {
  name: "SupervisoryRole",
  components: {
    DateRangePicker,
    Alert,
    CSelect,
    CText,
  },
  props: {
    employeeDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      delegateeArray: [],
      delegatee: "",
      delegationReason: `To fill ${this.employeeDetails.fname} ${this.employeeDetails.lname}'s role`,
      delegationPeriod: [],
    };
  },
  methods: {
    convertToISO(data) {
      const originalDate = new Date(data);

      const utcDate = new Date(
        originalDate.getTime() - originalDate.getTimezoneOffset() * 60000
      );

      const isoDate = utcDate.toISOString();

      return isoDate;
    },
    getEmployees() {
      this.$_getAllEmployeesWithQueries(``).then(({ data }) => {
        data.data.employees.forEach((employee) => {
          this.delegateeArray.push({
            id: employee.userId,
            name: `${employee.fname} ${employee.lname}`,
          });
        });
        this.loading = false;
      });
    },
    onSubmit() {
      this.isLoading = true;
      const payload = {
        orgId: this.$orgId,
        initiatedBy: this.$AuthUser.id,
        delegator: this.$AuthUser.id,
        delegatee: this.delegatee,
        supportType: "engagement",
        delegatedTask: "",
        delegationReason: "vacant",
        delegationPeriodStart: this.convertToISO(
          _.first(this.delegationPeriod)
        ),
        delegationPeriodEnd: this.convertToISO(_.last(this.delegationPeriod)),
      };

      if (this.delegationId) payload.id = this.delegationId;
      const query = payload.id ? "&update=true" : null;

      this.$_employeeActionDelegateEmployee({ payload, query })
        .then(({ data }) => {
          const message = "Delegation sent for approval successfully!";
          this.$toasted.success(message, { duration: 3000 });
          this.$emit("success", data);
          this.isLoading = false;
          this.isOpen = false;
        })
        .catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getEmployees();
  },
};
</script>
