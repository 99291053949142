<template>
  <div class="w-full h-full pt-5 px-3">
    <template>
      <div class="flex flex-row justify-start gap-4">
        <back-button
          label="Back"
          @onClick="$router.back()"
          variant="secondary"
          class="mr-4"
        />
        <div class="flex gap-3 my-2">
          <h1 class="text-xl text-left font-extrabold mt-1">Employees</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else class="px-3 my-2">
        <div class="flex flex-col w-full h-full">
          <div class="w-full flex py-2 gap-1 border-b-2">
            <p class="text-lg font-bold">{{ tabTitle }}</p>
          </div>
          <employment-details
            :employment-details="employeeDetails"
            slug="disengage"
          />
        </div>

        <div class="w-full flex my-2 justify-center">
          <div class="my-3 w-4/5 border-2" />
        </div>

        <div class="w-full my-8 flex gap-3 px-3">
          <div class="w-1/4 flex flex-col p-3">
            <side-bar
              :tabs="sideTabs"
              :current-tab="currentTab"
              @currentTab="currentTab = $event"
            />
          </div>
          <div class="flex flex-col border" style="width: 1px" />

          <div class="w-3/4 flex flex-col mr-1">
            <Intro
              v-if="currentTab === 'Intro'"
              :employee-details="employeeDetails"
            />
            <BasicInfo
              v-if="currentTab === 'Basic Info'"
              :employee-details="employeeDetails"
              @infoPayload="setBasicInfo"
            />
            <SupervisoryRole
              v-if="currentTab === 'Supervisory Role'"
              :employee-details="employeeDetails"
            />
            <Administrative
              v-if="currentTab === 'Administrative Privileges'"
              :employee-details="employeeDetails"
            />
            <Approvals
              v-if="currentTab === 'Approval Privileges'"
              :employee-details="employeeDetails"
            />
            <Confirm
              v-if="currentTab === 'Confirm'"
              :employee-details="employeeDetails"
              :payload-data="disengagePayload"
            />
          </div>
        </div>

        <div class="flex justify-end gap-4">
          <div
            class="flex mt-2 gap-2 cursor-pointer"
            @click="navigateToPrevTab"
            v-if="sideTabIndex > 1"
          >
            <Icon size="xs" icon-name="icon-arrow-left" />
            <div class="font-10 mr-4">
              <p class="text-romanSilver">
                {{ sideTabIndex - 1 }}/{{ sideTabs.length }}
                Previous
              </p>
              <p class="uppercase font-bold">
                {{ sideTabs[sideTabIndex - 2] }}
              </p>
            </div>
          </div>

          <card
            class="w-10 text-xl mr-2 font-extrabold flex justify-center h-10"
          >
            <p class="text-center my-2">{{ sideTabIndex }}</p>
          </card>

          <div class="flex" v-if="sideTabIndex < sideTabs.length">
            <div class="flex mt-2 cursor-pointer" @click="navigateToNextTab">
              <div class="font-10 mr-4">
                <p class="text-flame">
                  {{ sideTabIndex + 1 }}/{{ sideTabs.length }}
                  Next
                </p>
                <p class="uppercase font-bold">
                  {{ sideTabs[sideTabIndex] }}
                </p>
              </div>
              <Icon size="xs" icon-name="icon-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import employmentDetails from "@/components/confirmation/employmentDetails";
import Card from "@/components/Card";
import SideBar from "./sideBar";
import Intro from "./layouts/intro";
import BasicInfo from "./layouts/basicInfo";
import SupervisoryRole from "./layouts/supervisoryRole";
import Administrative from "./layouts/administrative";
import Approvals from "./layouts/approvals";
import Confirm from "./layouts/confirm";

export default {
  name: "ViewAssesment",
  components: {
    BackButton,
    Breadcrumb,
    Intro,
    employmentDetails,
    Administrative,
    SupervisoryRole,
    Approvals,
    SideBar,
    BasicInfo,
    Card,
    Confirm
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Employees",
          href: "Employees",
          id: "Employees",
        },
        {
          disabled: false,
          text: "Employee Directory",
          href: "EmployeeDirectory",
          id: "Employee Directory",
        },
        {
          disabled: false,
          text: "Disengagement Checklist",
          id: "Disengagement Checklist",
        },
      ],
      employeeDetails: {},
      currentTab: "Intro",
      loading: true,
      arrayTabs: [],
      sideTabIndex: null,
      allEmployeeDetails: {},
      payrollPayload: {},
      payload: {},
      actionSlug: "",
      tabTitle: "",
      disengagePayload: {},
    };
  },
  computed: {
    sideTabs() {
      const mockTabs = this.arrayTabs.slice();
      if (Object.keys(this.employeeDetails.roles).length === 0) {
        mockTabs.splice(3, 1);
      }
      if (
        this.employeeDetails.userDesignation &&
        !this.employeeDetails.userDesignation.isSupervisory
      ) {
        mockTabs.splice(2, 1);
      }
      return mockTabs;
    },
  },
  methods: {
    setBasicInfo(data) {
      this.disengagePayload = data;
    },
    navigateToNextTab() {
      const nextTabIndex = this.sideTabIndex + 1;
      if (nextTabIndex <= this.sideTabs.length) {
        this.currentTab = this.sideTabs[nextTabIndex - 1];
        this.sideTabIndex = nextTabIndex;
      }
    },
    navigateToPrevTab() {
      const prevTabIndex = this.sideTabIndex - 1;
      if (prevTabIndex < this.sideTabs.length) {
        this.currentTab = this.sideTabs[prevTabIndex - 1];
        this.sideTabIndex = prevTabIndex;
      }
    },
    formatDate(value) {
      const dateString = value;
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}/${month}/${day}`;
    },
  },

  created() {
    this.arrayTabs.push(
      "Intro",
      "Basic Info",
      "Supervisory Role",
      "Administrative Privileges",
      "Approval Privileges",
      "Confirm"
    );
  },

  mounted() {
    this.$_getOneEmployeeAll({
      id: this.$route.params.id,
    }).then((response) => {
      this.employeeDetails = response.data;
      this.loading = false;
    });
    this.sideTabIndex = this.arrayTabs.indexOf("Intro") + 1;
  },
};
</script>
